import type {
  AlgoliaProductType,
  CLPriceInfo,
} from '@integration-layer/components/Algolia/AlgoliaAisInfiniteHits.props'
import type { RenderState } from 'instantsearch.js'
import type { InstantSearch, UiState } from 'instantsearch.js/es/types'
import { ref } from 'vue'

export default function useProductTileClPrices() {
  const { $cl } = useNuxtApp()

  // A list of the SKUs already fetched in previous PLP pages
  const fetchedSkus = ref<string[]>([])
  /**
   * Reactivelytores the prices of the SKUs passed as arg to the fetchSkuPrices function
   */
  const skuPrices = ref<Record<string, CLPriceInfo>>({})

  /**
   * @title fetchSkuPrices
   *
   * Fetches the prices of SKUs and updates the `skuPrices` ref.
   *
   * @param skuListParam - An array of SKU codes.
   * @returns void
   */
  const fetchSkuPrices = async (skuListParam: string[]) => {
    if (!skuListParam.length) return
    const skusToFetch =
      skuListParam.filter(skuCode => !fetchedSkus.value.includes(skuCode)) ?? []

    fetchedSkus.value = [...new Set([...fetchedSkus.value, ...skuListParam])]

    if (!skusToFetch.length) {
      return
    }

    const newSkuPrices = await Promise.all(
      chunk(skusToFetch, 10).map(skuListParam =>
        $cl.skus.list({
          include: ['prices'],
          filters: { code_in: skuListParam.join(',') },
          pageSize: 10,
        })
      )
    ).then(responses => responses.flat())

    const newPricesMappedObject = newSkuPrices.reduce(
      (skuPricesBySkuCode, sku) => {
        const price = sku.prices?.at(0)

        return {
          ...skuPricesBySkuCode,
          [sku.code]: {
            originalPrice: price?.compare_at_amount_float!,
            discountedPrice: price?.amount_float ?? '',
            discount: price?.amount_float
              ? percentageDiscount(
                  price?.compare_at_amount_float!,
                  price?.amount_float!
                )
              : '',
            // TODO: (tracked #1204) 1. Handle discountLabel
            // discountLabel: '',
          },
        }
      },
      {}
    )

    skuPrices.value = {
      ...skuPrices.value,
      ...newPricesMappedObject,
    }
  }

  /**
   * Same as fetchSkuPrices but takes as argument an AlgoliaInstantSearchInstance and the Algolia index name, and assignes the new prices to the skuPrices ref.
   * Meant for usage inside an Algolia InstantSearch middleware
   */
  const fetchSkuPricesAlgoliaMiddleware = async (
    instantSearchInstance: InstantSearch<UiState, UiState>
  ) => {
    const hits = instantSearchInstance.mainIndex.getResults()?.hits
    const hitsSkus =
      hits
        ?.map(item => item as unknown as AlgoliaProductType)
        ?.map(item => item?.size?.[0]?.SKU ?? null)
        ?.filter(code => isNonNullable(code)) ?? []

    await fetchSkuPrices(hitsSkus)
  }

  const resetAll = () => {
    fetchedSkus.value = []
    skuPrices.value = {}
  }

  return {
    skuPrices,
    fetchSkuPrices,
    fetchSkuPricesAlgoliaMiddleware,
    resetAll,
  }
}
